import React, { useState } from "react";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import { SimpleInput } from "../../../components/Inputs/SimpleInput";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { Button } from "./../../../ESComponents/Buttons";
import logo from "../../../assets/img/icons/svg/peach.svg";
import "./scss/login.scss";
import { NavLink, useHistory } from "react-router-dom";
import { UsersController } from "../../../utils/Controller/UsersController";
import { ErrorBlock } from "../../../ESComponents/Blocks";
import peach from "../../../assets/img/icons/svg/peach.svg";
import bg from "../../../assets/img/backgrounds/hero.jpg";
import { SplashScreen } from "../../../ESComponents/SplashScreen";
import { NavButton } from "../../../layouts/menu/Menu";
import { optionalFn } from "../../../core/helpers";
import { ReactSVG } from "react-svg";
import { FontIcon } from "../../../components/Icons/FontIcon";
export function Login({ mail = "" }) {
  const [state, setState] = useCState({ email: mail, password: "" });
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(0);
  const { email, password } = state;
  const history = useHistory();
  return (
    <>
      <SplashScreen
        open={loading}
        msg="Cargando..."
        background={bg}
        icon={peach}
      />
      <form
        className="login"
        onSubmit={(ev) => {
          ev.preventDefault();
          setLoading(1);
          let user = new UsersController();
          user.login(state).then(({ code }) => {
            if (code === 200) {
              setError(0);
              setLoading(0);
              history.push("/home");
            } else {
              setError(1);
              setLoading(0);
            }
          });
        }}>
        <GridContainer>
          <NavLink to={"/home"} className="goBack">
            <FontIcon icon="home" />
          </NavLink>
          <GridItem xs={12}>
            <ReactSVG src={logo} className="logo" />
          </GridItem>
          <GridItem xs={12}>
            <h1>Iniciar sesión</h1>
          </GridItem>
          <GridItem xs={12}>
            <ErrorBlock open={error}>
              uh... parece que este estos datos no son validos.
            </ErrorBlock>
          </GridItem>
          <GridItem xs={12}>
            <SimpleInput
              placeholder="example@example.com"
              title="Correo"
              type="email"
              name="email"
              value={email}
              onChange={({ target }) => {
                setState({ email: target.value });
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <SimpleInput
              placeholder="ex: aPassword"
              title="Contraseña"
              type="password"
              name="password"
              value={password}
              onChange={({ target }) => {
                setState({ password: target.value });
              }}
            />
            <a
              className="forgot"
              href="/#"
              onClick={(ev) => {
                ev.preventDefault();
                setLoading(1);
                const user = new UsersController();
                user.recoveryMail(email).then(() => {
                  history.push("/recovery");
                });
              }}>
              ¿Olvidaste tu contraseña?
            </a>
          </GridItem>
          <GridItem xs={12}>
            <Button>Ingresar</Button>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
}
export function AuthContainer({ onChange }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(0);
  return (
    <>
      <SplashScreen
        open={loading}
        msg="Cargando..."
        background={bg}
        icon={peach}
      />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          const fetch = new UsersController();
          setLoading(1);
          fetch.checkForMail(email).then(({ code }) => {
            setLoading(0);
            if (code === 200) {
              //history.push(`/login?email=${email}`);
              optionalFn(onChange)(email);
            } else {
              setError(1);
            }
          });
        }}>
        <GridContainer className="authContainer">
          <NavLink to={"/home"} className="goBack">
            <FontIcon icon="home" />
          </NavLink>
          <GridItem xs={12}>
            <ReactSVG src={logo} className="logo" />
          </GridItem>
          <GridItem xs={12}>
            <h1>Iniciar sesión</h1>
          </GridItem>
          <GridItem xs={12}>
            <SimpleInput
              errorStatus={error}
              errorMessage="¿Este correo no existe, deseas registrarte?"
              title="Correo"
              required
              type="email"
              onChange={({ target }) => {
                setEmail(target.value);
                if (target.value === "") {
                  setError(0);
                }
              }}
            />
          </GridItem>

          <GridItem xs={12} s={6}>
            <NavButton
              path={`/user/register?email=${email}`}
              className=" side thirdButton"
              name="Regístrame!"
            />
          </GridItem>
          <GridItem xs={12} s={6}>
            <Button>
              <h2>Siguiente</h2>
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
}
export function AuthWizard() {
  const [validMail, setMail] = useState(0);
  if (validMail) {
    return <Login mail={validMail} />;
  }
  return (
    <>
      <AuthContainer
        onChange={(mail) => {
          setMail(mail);
        }}
      />
    </>
  );
}
