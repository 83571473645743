import React from "react";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import { SimpleInput } from "../../../components/Inputs/SimpleInput";
import { Textarea } from "../../../components/Inputs/Textarea";
import { SecundaryHero } from "../hero/Hero";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { ContactController } from "./../../../utils/Controller/ContactController";
import { Button } from "../../../ESComponents/Buttons";
export function ContactPage() {
  const [state, setState] = useCState({
    nombre: "",
    correo: "",
    telefono: "",
    asunto: "",
    mensaje: "",
  });
  const { nombre, correo, telefono, asunto, mensaje } = state;
  return (
    <SecundaryHero title="Contactanos">
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          const fetch = new ContactController();
          fetch.post(state);
        }}>
        <GridContainer>
          <GridItem>
            <SimpleInput
              title="Nombre completo"
              value={nombre}
              required
              onChange={({ target }) => {
                setState({ nombre: target.value });
              }}
            />
          </GridItem>
          <GridItem>
            <SimpleInput
              title="Correo"
              value={correo}
              required
              onChange={({ target }) => {
                setState({ correo: target.value });
              }}
              type="email"
            />
          </GridItem>
          <GridItem>
            <SimpleInput
              title="Telefono"
              value={telefono}
              required
              onChange={({ target }) => {
                setState({ telefono: target.value.replace(/[^0-9.]/g, "") });
              }}
              type="tel"
            />
          </GridItem>
          <GridItem>
            <SimpleInput
              title="Asunto"
              value={asunto}
              required
              onChange={({ target }) => {
                setState({ asunto: target.value });
              }}
            />
          </GridItem>
          <GridItem>
            <Textarea
              title="Mensaje"
              value={mensaje}
              required
              onChange={(value) => {
                setState({ mensaje: value });
              }}
            />
          </GridItem>
        </GridContainer>
        <Button>Enviar</Button>
      </form>
    </SecundaryHero>
  );
}
