export const Post = {
  precio: "",
  user_create: "",
  correo: "",
  descripcion: "",
  telefono: "",
  titulo: "",
  whatsapp: "",
  nombre: "",
  status: "",
  fecha_creacion: "",
  fkCategoria: "",
  fkCiudad: "",
  fkCupon: "2019",
  fkEstado: "",
  fkGenero: "",
  fkseccion: "1",
  fkRecordStatus: "",
  imagen: "",
  genero: "",
};
