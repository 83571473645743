import React, { useRef } from "react";
import { useState } from "react";
import { optionalFn } from "../../core/helpers";
export function LadderScroll({ children, onScrolling, onScrollEnds, ...rest }) {
  const [startPoint, setPoint] = useState({});
  const [isDragging, toggleDrag] = useState(0);
  const container = useRef(null);
  const lastUpdateDis = { x: 0, y: 0 };
  let formatPos = (clientX, clientY, ev) => {
    let distanceX = clientX - startPoint.x;
    let distanceY = startPoint.y - clientY;

    const response = {
      distanceX,
      distanceY,
      relativeDisX: distanceX - lastUpdateDis.x,
      relativeDisy: distanceY - lastUpdateDis.y,
      cumulativeX: distanceX + lastUpdateDis.x,
      cumulativeY: distanceY + lastUpdateDis.y,
      size: ev.target.getBoundingClientRect(),
      ...startPoint,
    };

    lastUpdateDis.x = distanceX;
    lastUpdateDis.y = distanceY;
    return response;
  };
  let catchTouchMove = (ev) => {
    ev.persist();
    let { clientX, clientY } = ev.changedTouches[0];
    const response = formatPos(clientX, clientY, ev);
    optionalFn(onScrolling)(response, container);
  };
  return (
    <div
      ref={container}
      {...rest}
      onTouchMove={catchTouchMove}
      onMouseMove={(ev) => {
        if (ev.buttons === 1) {
          const { clientX, clientY } = ev;
          let response = formatPos(clientX, clientY, ev);
          optionalFn(onScrolling)(response, container);
        }
      }}
      onMouseDown={(ev) => {
        setPoint({ x: ev.clientX, y: ev.clientY });
        toggleDrag(1);
      }}
      onTouchStart={(ev) => {
        let touches = ev.changedTouches[0];
        setPoint({ x: touches.clientX, y: touches.clientY });
        toggleDrag(1);
      }}
      onTouchEnd={(ev) => {
        let { clientX, clientY } = ev.changedTouches[0];
        const response = formatPos(clientX, clientY, ev);
        optionalFn(onScrollEnds)(response, container);
        toggleDrag(0);
      }}
      onMouseOut={(ev) => {
        const { clientX, clientY } = ev;
        const response = formatPos(clientX, clientY, ev);
        if (!isDragging) {
          return false;
        }
        toggleDrag(0);
        optionalFn(onScrollEnds)(response, container);
      }}
      onMouseUp={(ev) => {
        toggleDrag(0);
        const { clientX, clientY } = ev;
        const response = formatPos(clientX, clientY, ev);
        optionalFn(onScrollEnds)(response, container);
      }}
    >
      {children}
    </div>
  );
}
