import React from "react";
import hero from "../../../assets/img/backgrounds/hero.jpg";
import { SecundaryHero } from "../hero/Hero";
export function PrivacyPage() {
  return (
    <SecundaryHero title={"Aviso de privacidad"} image={hero}>
      <div className="the_content_wrapper">
        <p style={{ textAlign: "justify" }}>
          De conformidad con la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares hacemos de su conocimiento que Buscamor,
          en Guadalajara, Jalisco es responsable de recabar sus datos
          personales, del uso que se le dé a los mismos y de su protección.
        </p>
        <p style={{ textAlign: "justify" }}>
          Su información personal será utilizada para las siguientes finalidad:
          informar de los servicios que ha solicitado; notificarle sobre
          nuestras promociones que tengan relación con los servicios ya
          contratados o adquiridos; comunicarle sobre cambios en los mismos;
          elaborar estudios y programas que son necesarios para determinar
          hábitos de consumo; cumplir con las obligaciones fiscales de esta
          empresa; generar la facturación correspondiente y en general para dar
          cumplimiento a las obligaciones que hemos contraído con usted.
        </p>
        <p style={{ textAlign: "justify" }}>
          Para las finalidades antes mencionadas, requerimos obtener algunos de
          los siguientes datos personales:
        </p>
        <ul style={{ textAlign: "justify" }}>
          <li>Nombre completo</li>
          <li>Teléfono fijo y/o celular</li>
          <li>Correo electrónico</li>
          <li>Dirección</li>
        </ul>
        <p style={{ textAlign: "justify" }}>
          Le informamos que usted tiene derecho al Acceso, Rectificación y
          Cancelación de sus datos personales, a Oponerse al tratamiento de los
          mismos o a revocar el consentimiento que para dicho fin nos haya
          otorgado, a través de los procedimientos y formatos que hemos
          implementado para cumplir con la ley de la materia.
        </p>
        <p style={{ textAlign: "justify" }}>
          Asimismo, le informamos que sus datos personales pueden ser
          transferidos a personas distintas a esta empresa. En este sentido, su
          información puede ser compartida con empresas de paquetería y/o
          transporte para hacerle llegar a su domicilio los productos
          solicitados, con sociedades que dictaminan los estados financieros de
          esta empresa y con instituciones de banca múltiple para dar
          cumplimiento a las obligaciones que el responsable de los datos tiene
          con usted.
        </p>
        <p style={{ textAlign: "justify" }}>
          Si usted no manifiesta su oposición para que sus datos personales sean
          transferidos, se entenderá que ha manifestado su consentimiento para
          ello. Cualquier modificación a este aviso de privacidad podrá
          consultarla en la siguiente página de internet
          <a href="https://buscamor.mx/home">https://buscamor.mx</a>Fecha de
          última actualización 07 de Octubre del 2020
        </p>
        <p style={{ textAlign: "justify" }} />
      </div>
    </SecundaryHero>
  );
}
