import React, { useState } from "react";
import { InfiniteList } from "../../../ESComponents/Lists";
import { GridContainer, GridItem } from "./../../../components/Grid/Grid";
import { PostsList } from "./PostsViews";
import { PostsController } from "./../../../utils/Controller/PostsController";
import { useHistory, useParams } from "react-router-dom";
import { IconicInput } from "../../../components/Inputs/SimpleInput";
import "./scss/posts.scss";
export function SearchView() {
  const { query } = useParams();
  return (
    <GridContainer className="postsContainer">
      <GridItem md={12}>
        <h1>Busqueda: {query}</h1>
      </GridItem>
      <GridItem md={12}>
        <SearchInput value={query} key={query} />
      </GridItem>
      <GridItem md={12}>
        <QueryPosts key={query} search={query} />
      </GridItem>
    </GridContainer>
  );
}
export function QueryPosts({ search }) {
  return (
    <InfiniteList
      defaultList={PostsList}
      loadBy={() => {
        const fetch = new PostsController();
        if (search) return fetch.getQueriedPosts({ search });
        return fetch.get();
      }}
    />
  );
}
export function SearchInput({ value }) {
  const history = useHistory();
  const [search, setSearch] = useState(value);
  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        history.push(`/search/${search}`);
      }}
    >
      <IconicInput
        placeholder="Buscar..."
        value={search}
        onChange={(ev) => {
          setSearch(ev.target.value);
        }}
      />
    </form>
  );
}
