import React from "react";
import { PostsByProfile } from "./PostsViews";
import postGirl from "../../../assets/img/backgrounds/postGirl.jpg";
import senos from "../../../assets/img/backgrounds/Surgeon.jpg";
import sexshop from "../../../assets/img/backgrounds/sexshop.jpg";
import { SecundaryHero } from "../hero/Hero";
export function ScortsContainer() {
  return (
    <>
      <SecundaryHero title={"Escorts"} image={postGirl}>
        <PostsByProfile profileID={3} />
      </SecundaryHero>
    </>
  );
}
export function MedicContainer() {
  return (
    <>
      <SecundaryHero title={"Médicos"} image={senos}>
        <PostsByProfile profileID={4} />
      </SecundaryHero>
    </>
  );
}
export function SexShopContainer() {
  return (
    <>
      <SecundaryHero title={"SexShops"} image={sexshop}>
        <PostsByProfile profileID={5} />
      </SecundaryHero>
    </>
  );
}
