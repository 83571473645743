import React from "react";
import { GridContainer, GridItem } from "../components/Grid/Grid";
import { Image } from "./../components/Images/Image";

export function H2({ children }) {
  return (
    <>
      <h2
        style={{
          textAlign: "center",
          color: "#444",
        }}
      >
        {children}
      </h2>
    </>
  );
}

export function IconLink({ icon, title, to }) {
  return (
    <a href={to} className="iconLink">
      <GridContainer>
        <GridItem xs={3}>
          <Image src={icon} style={{ width: "33px", height: "33px" }} />
        </GridItem>
        <GridItem xs={9}>{title}</GridItem>
      </GridContainer>
    </a>
  );
}
