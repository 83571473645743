import React from "react";
import { GridContainer, GridItem } from "../../components/Grid/Grid";
import { NavLink } from "react-router-dom";
import peach from "../../assets/img/icons/svg/peach.svg";
import bulb from "../../assets/img/icons/svg/bulb.svg";
import search from "../../assets/img/icons/svg/search.svg";
import account from "../../assets/img/icons/svg/account.svg";
import signIn from "../../assets/img/icons/svg/sign-in-alt-solid.svg";
import cam from "../../assets/img/icons/svg/cam2.svg";
import "./scss/menu.scss";
import { ReactSVG } from "react-svg";
import { LoginManager } from "./../../utils/LoginManager";
import { UserButton } from "../../views/web/user/UserComponents";

export function MobileMenu() {
  const lm = new LoginManager();
  let logged = lm.isLogged();
  let loggedSize = logged ? 2 : 4;
  return (
    <GridContainer className="mobileMenu" columns={logged ? 10 : 12}>
      <GridItem
        xs={loggedSize}
        style={{
          order: "1",
        }}
      >
        <NavButton path="/home" name="Inicio" icon={peach} />
      </GridItem>
      <GridItem
        xs={loggedSize}
        style={{
          order: logged ? "3" : "2",
        }}
      >
        <NavButton
          path={logged ? "/register/post" : "/user/register"}
          name="Anunciate"
          icon={cam}
        />
      </GridItem>
      <FilteredMenu logged={logged} />
    </GridContainer>
  );
}
export function NavButton({ path, name, icon, className }) {
  return (
    <NavLink to={path} className={`navButton ${className}`}>
      <ReactSVG className="menuIcon" alt={name} src={icon} />
      <h3>{name}</h3>
    </NavLink>
  );
}
export function FilteredMenu({ logged }) {
  let loggedSize = 2;
  if (!logged)
    return (
      <GridItem
        xs={4}
        style={{
          order: "3",
        }}
      >
        <NavButton path="/auth" name="Acceder" icon={signIn} />
      </GridItem>
    );
  return (
    <>
      <GridItem
        xs={loggedSize}
        style={{
          order: "2",
        }}
      >
        <NavButton path="/me/posts" name="Archivo" icon={bulb} />
      </GridItem>
      <GridItem
        xs={loggedSize}
        style={{
          order: "3",
        }}
      >
        <NavButton path="/search" name="Buscar" icon={search} />
      </GridItem>
      <GridItem
        xs={loggedSize}
        style={{
          order: "4",
        }}
      >
        <NavButton path="/account" name="Cuenta" icon={account} />
      </GridItem>
    </>
  );
}
export function MenuItem({ path, className, name }) {
  return (
    <NavLink to={path} className={`menuItem ${className}`}>
      {name}
    </NavLink>
  );
}
export function DeskMenu() {
  let lm = new LoginManager();
  let logged = lm.isLogged();
  return (
    <div className="deskMenu">
      <GridContainer>
        <GridItem xs={3} lg={3}>
          <MenuItem
            path={logged ? "/register/post" : "/user/register"}
            name="Anunciate"
          />
        </GridItem>
        <GridItem xs={3} lg={3}>
          <MenuItem path="/posts/news" name="Novedades" />
        </GridItem>
        <GridItem xs={3} lg={3}>
          <MenuItem path="/posts/top" name="Populares" />
        </GridItem>
        <GridItem xs={3} lg={3}>
          <MenuItem path="/contact" name="Contactanos" />
        </GridItem>
      </GridContainer>
    </div>
  );
}
export function ConsumerMenu() {
  const lm = new LoginManager();
  let logged = lm.isLogged();
  return (
    <div className="consumerMenu">
      <GridContainer>
        {logged ? (
          <>
            <GridItem xs={7}>
              <NavButton path="/me/posts" name="Archivo" icon={bulb} />
            </GridItem>
            <GridItem xs={5}>
              <UserButton />
            </GridItem>
          </>
        ) : (
          <GridItem xs={12} className={"access"}>
            <NavButton path="/auth" name="Acceder" icon={signIn} />
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
