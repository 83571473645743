import React, { useState, useEffect } from "react";
import { GridContainer, GridItem } from "./../../../components/Grid/Grid";
import { Image } from "../../../components/Images/Image";
import "./scss/account.scss";
import { UsersController } from "./../../../utils/Controller/UsersController";
import { User } from "./../../../utils/Models/User";
import { optionalFn, filesToB64 } from "../../../core/helpers";
import { SimpleInput } from "../../../components/Inputs/SimpleInput";
import {
  Button,
  SecondaryButton,
  ThirdButton,
} from "./../../../ESComponents/Buttons";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { LoginManager } from "./../../../utils/LoginManager";
import { FontIcon } from "./../../../components/Icons/FontIcon";
import { useHistory } from "react-router-dom";

/**
 * Retorna una lista con la informacion de usuario.
 * @returns component
 */
export function Account() {
  const [user, setUser] = useState(User);
  const {
    id,
    username,
    name,
    email,
    paterno,
    materno,
    telefono,
    whatsapp,
    picture_path,
  } = user;
  const history = useHistory();
  const loadUser = () => {
    const lm = new LoginManager();
    const user = new UsersController();
    let userData = user.me();
    let profile = userData;
    setUser(profile);
    user.show(profile.id).then((response) => {
      if (response.code < 300) setUser(response.data.content[0]);
      else {
        lm.logout();
        history.push("/home");
      }
    });
  };
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <GridContainer className="account">
      <GridItem xs={12} className="profileContainer">
        <ProfileImage id={id} src={picture_path} />
      </GridItem>

      <GridItem xs={12}>
        <GenericData id={id} title={"Nombre de usuario"} value={{ username }} />
      </GridItem>

      <GridItem xs={12}>
        <GenericData id={id} title={"Correo"} value={{ email }} />
      </GridItem>
      <GridItem xs={12}>
        <GenericData
          id={id}
          title={"Contraseña"}
          type="password"
          value={{ password: "**********" }}
        />
      </GridItem>

      <GridItem xs={12}>
        <NameData
          id={id}
          title={"Nombre"}
          values={{ name, paterno, materno }}
        />
      </GridItem>
      <GridItem xs={12}>
        <GenericData
          id={id}
          title={"Teléfono"}
          type="tel"
          value={{ telefono }}
        />
      </GridItem>
      <GridItem xs={12}>
        <GenericData
          id={id}
          title={"WhatsApp"}
          type="tel"
          value={{ whatsapp }}
        />
      </GridItem>
      <GridItem xs={12}>
        <ThirdButton
          onClick={() => {
            const lm = new LoginManager();
            lm.logout();
            history.push("/home");
          }}>
          <FontIcon icon="sign-out-alt" /> Cerrar sesión
        </ThirdButton>
      </GridItem>
    </GridContainer>
  );
}
export function ProfileImage({ id, src }) {
  const [image, setImage] = useState("");
  useEffect(() => {
    setImage(`https://${src}`);
  }, [src]);
  return (
    <>
      <label htmlFor="profileImg" className="profileSelector">
        <Image src={image} type="profile" />
      </label>
      <input
        type="file"
        id="profileImg"
        className="hidden"
        onChange={({ target }) => {
          const fetch = new UsersController();
          fetch.update(id, { imagen: target.files[0] });
          filesToB64(target.files).then((files) => {
            setImage(files[0].target.result);
          });
        }}
      />
    </>
  );
}
export function InfoData({ title, value, onClick }) {
  return (
    <div
      className="InfoData"
      onClick={(ev) => {
        optionalFn(onClick)(ev, { title, value });
      }}>
      <GridContainer>
        <GridItem xs={12}>
          <h4>{title}:</h4>
          <hr />
        </GridItem>
        <GridItem className="value" xs={12}>
          {value}
        </GridItem>
      </GridContainer>
    </div>
  );
}
function NameData({ id, title, values }) {
  const [state, setState] = useCState(values);
  const { name, paterno, materno } = state;
  const [open, toggle] = useState(0);
  useEffect(() => {
    setState(values);
    // eslint-disable-next-line
  }, [values]);
  if (open) {
    return (
      <NameForm
        id={id}
        values={state}
        onSave={(fullname) => {
          toggle(0);
          fullname.name = fullname.nombre;
          setState(fullname);
        }}
        onClose={() => {
          toggle(0);
        }}
      />
    );
  }
  return (
    <InfoData
      title={title}
      value={`${name} ${paterno} ${materno}`}
      onClick={() => {
        toggle(1);
      }}
    />
  );
}
function NameForm({ id, values, onSave, onClose }) {
  values.nombre = values.name;
  const [state, setState] = useCState(values);
  const { nombre, paterno, materno } = state;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <SimpleInput
          title={"Nombre"}
          value={nombre}
          onChange={({ target }) => {
            setState({ nombre: target.value });
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          onChange={({ target }) => {
            setState({ paterno: target.value });
          }}
          title={"Apellido Paterno"}
          value={paterno}
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          onChange={({ target }) => {
            setState({ materno: target.value });
          }}
          title={"Apellido Materno"}
          value={materno}
        />
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onSave)(state);
            let user = new UsersController();
            user.update(id, state);
          }}>
          Guardar
        </Button>
      </GridItem>
      <GridItem xs={6}>
        <SecondaryButton
          onClick={() => {
            optionalFn(onClose)();
          }}>
          Cancelar
        </SecondaryButton>
      </GridItem>
    </GridContainer>
  );
}
function GenericData({ id, title, type, value, ...rest }) {
  const [open, toggle] = useState(0);
  const [val, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);

  let content = Object.values(val)[0];
  if (type === "password") {
    content = "**********";
  }
  if (open) {
    return (
      <GenericForm
        value={value}
        title={title}
        type={type}
        id={id}
        {...rest}
        onSave={(data) => {
          toggle(0);
          setValue(data);
        }}
        onClose={() => {
          toggle(0);
        }}
      />
    );
  }
  return (
    <InfoData
      title={title}
      value={content}
      onClick={() => {
        toggle(1);
      }}
    />
  );
}
function GenericForm({
  id,
  title,
  type = "text",
  value,
  onSave,
  onClose,
  ...rest
}) {
  const name = Object.keys(value)[0];
  let data = {};
  data[name] = type === "password" ? "" : value[name];
  const [val, setValue] = useState(data);
  const content = Object.values(val)[0];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <SimpleInput
          type={type}
          title={title}
          onChange={({ target }) => {
            let data = {};
            data[name] = target.value;
            setValue(data);
          }}
          value={content}
        />
      </GridItem>
      <GridItem xs={6}>
        <Button
          onClick={() => {
            optionalFn(onSave)(val);
            const user = new UsersController();
            user.update(id, val);
          }}>
          Guardar
        </Button>
      </GridItem>
      <GridItem xs={6}>
        <SecondaryButton
          onClick={() => {
            optionalFn(onClose)();
          }}>
          Cancelar
        </SecondaryButton>
      </GridItem>
    </GridContainer>
  );
}
