import React, { useState, useEffect } from "react";
import { CategoryController } from "./../../../utils/Controller/CategoryController";
import "./scss/catComponents.scss";
import { optionalFn } from "../../../core/helpers";
import { ScreenRangeContainer } from "../../../ESComponents/Blocks";
import { SelectCategory } from "../../../ESComponents/Selectors";
export function CategoriesGrid({ onSelected, hideEmpty, profileID }) {
  const [cats, setCats] = useState([]);
  const [selected, setSelected] = useState(0);
  let loadCategories = () => {
    let categories = new CategoryController();
    categories.get(profileID ? { id: profileID } : null).then(({ data }) => {
      setCats(data.content);
    });
  };
  useEffect(loadCategories, []);
  return (
    <div className="catGrid">
      <div
        className={`categoryButton ${selected === 0 ? "active" : ""}`}
        onClick={(ev) => {
          setSelected(0);
          optionalFn(onSelected)({ value: 0, label: "todos" });
        }}
      >
        TODOS
      </div>
      {cats.map((item, key) => {
        if (hideEmpty && item.anuncios_totales === 0) {
          return null;
        }
        return (
          <div
            className={`categoryButton ${selected === item.id ? "active" : ""}`}
            key={key}
            onClick={(ev) => {
              setSelected(item.id || item.fkCategoria);
              optionalFn(onSelected)({
                value: item.id || item.fkCategoria,
                label: item.categoria,
              });
            }}
          >
            {item.categoria}
          </div>
        );
      })}
    </div>
  );
}
export function CategoriesSelector({ onChange, hideEmpty, profileID }) {
  return (
    <>
      <ScreenRangeContainer max={1000}>
        <SelectCategory
          hideEmpty
          profileID={profileID}
          onChange={(ev) => {
            optionalFn(onChange)(ev);
          }}
        />
      </ScreenRangeContainer>
      <ScreenRangeContainer min={1001}>
        <CategoriesGrid
          hideEmpty
          profileID={profileID}
          onSelected={(id) => {
            optionalFn(onChange)(id);
          }}
        />
      </ScreenRangeContainer>
    </>
  );
}
