import React from "react";
import "./scss/inputs.scss";
/**
 * Permite crear un boton con fondo gradiant (desperdicio de componente...)
 * @param {children,deg,colors,onClick} props
 * @returns component
 */
export function GradientButton({
  children,
  deg = "90",
  colors,
  onClick,
  style,
  className,
  ...rest
}) {
  colors = formatColors(colors);
  return (
    <button
      {...rest}
      className={`gradientButton ${className}`}
      onClick={onClick}
      style={{
        ...style,
        background: `linear-gradient(${deg}deg, ${colors})`,
      }}
    >
      {children}
    </button>
  );
}
/**
 * Convierte el objeto de colores en una cadena compatible con css
 * @param {*} colors
 * @return string
 */
function formatColors(colors) {
  let arrColor = [];
  for (let index in colors) {
    let { color, stop } = colors[index];
    arrColor.push(`${color} ${stop}%`);
  }
  return arrColor.join(",");
}
