import React from "react";
import AsyncSelect from "react-select/async";
import Select, { components } from "react-select";
import downImg from "./../../assets/img/icons/down.png";
export function AsyncSelector({ title = "Select", ...rest }) {
  return (
    <>
      <label>{title}</label>
      <AsyncSelect {...rest} />
    </>
  );
}
export function SimpleSelect({
  title,
  placeholder = "selecciona uno",
  className,
  error,
  errMsg = "Campo invalido",
  ...rest
}) {
  return (
    <div className="simpleSelect">
      <label>{title}</label>
      <Select
        placeholder={placeholder}
        components={{ DropdownIndicator }}
        className={`simpleSelect ${className}`}
        classNamePrefix="simpleSelect"
        {...rest}
      />
      {error ? <p className="errorMessage">{errMsg}</p> : ""}
    </div>
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator className="dropdown" {...props}>
      <img alt="down" src={downImg} style={{ width: "33px" }} />
    </components.DropdownIndicator>
  );
}
