import { HomeContainer } from "../views/admin/home/HomeContainer";
import { Sandbox } from "../views/admin/Sandbox/Sandbox";
import { PostRegisterForm } from "./../views/web/posts/PostRegister";
import { RegisterWizard } from "./../views/web/user/RegisterForm";
import { Account } from "./../views/web/user/Account";
import { SingularPost } from "./../views/web/posts/SingularPost";
import { MyPosts, PopPosts } from "../views/web/posts/PostsViews";

import { ActivatePage } from "./../views/web/user/activate";

import { SearchView } from "./../views/web/posts/SearchView";

import { PrivacyPage } from "./../views/web/pages/privacyPolicy";

import { NoveltyPosts } from "./../views/web/posts/PostsViews";
import {
  ResendActivaingMail,
  ActivatedAccount,
  PasswordRecoveryForm,
  PasswordRecoveryInfo,
  Limit,
} from "../views/web/pages/Validations";

import { ContactPage } from "./../views/web/pages/Contact";
import {
  MedicContainer,
  ScortsContainer,
  SexShopContainer,
} from "./../views/web/posts/mainProfilePostsProfiles";
export const routes = [
  {
    redirect: true,
    path: "/",
    to: "/home",
    navbarName: "Redirect",
  },

  {
    path: "/home",
    sidebarName: "Inicio",
    navbarName: "Inicio",
    icon: "home",
    component: HomeContainer,
  },
  {
    path: "/escorts",
    sidebarName: "Escorts",
    navbarName: "Escorts",
    icon: "heart",
    component: ScortsContainer,
  },
  {
    path: "/medicos",
    sidebarName: "medico",
    navbarName: "medico",
    icon: "heart",
    component: MedicContainer,
  },
  {
    path: "/posts/news",
    sidebarName: "Novedades",
    navbarName: "Novedades",
    icon: "news",
    component: NoveltyPosts,
  },
  {
    path: "/posts/top",
    sidebarName: "Novedades",
    navbarName: "Novedades",
    icon: "news",
    component: PopPosts,
  },
  {
    path: "/activating",
    sidebarName: "active",
    navbarName: "active",
    icon: "heart",
    component: ActivatePage,
  },
  {
    path: "/search/:query?",
    sidebarName: "search",
    navbarName: "search",
    icon: "search",
    component: SearchView,
  },
  {
    path: "/privacy",
    sidebarName: "Aviso de privacidad",
    navbarName: "Aviso de privacidad",
    icon: "search",
    component: PrivacyPage,
  },

  {
    path: "/sexshop",
    sidebarName: "SexShop",
    navbarName: "SexShop",
    icon: "heart",
    component: SexShopContainer,
  },
  {
    path: "/register/post",
    sidebarName: "Públicar anuncio",
    navbarName: "",
    icon: "home",
    component: PostRegisterForm,
  },
  {
    path: "/me/posts",
    sidebarName: "Mis anuncios",
    navbarName: "",
    icon: "home",
    component: MyPosts,
  },
  {
    path: "/contact",
    sidebarName: "Contactanos",
    navbarName: "Contactanos",
    icon: "home",
    component: ContactPage,
  },

  {
    path: "/post/:id",
    sidebarName: "Ver Anuncio",
    navbarName: "",
    icon: "home",
    component: SingularPost,
  },

  {
    path: "/account",
    sidebarName: "Cuenta",
    navbarName: "",
    icon: "people",
    component: Account,
  },

  {
    path: "/user/register",
    sidebarName: "Públicar anuncio",
    navbarName: "",
    icon: "home",
    component: RegisterWizard,
  },

  {
    path: "/sandbox",
    sidebarName: "sandbox",
    navbarName: "sandbox",
    icon: "log-out",
    component: Sandbox,
  },
  {
    path: "/activated",
    sidebarName: "activated",
    navbarName: "activated",
    icon: "log-out",
    component: ActivatedAccount,
  },
  {
    path: "/resend",
    sidebarName: "resend",
    navbarName: "resend",
    icon: "log-out",
    component: ResendActivaingMail,
  },
  {
    path: "/recovery/",
    sidebarName: "recovery",
    navbarName: "recovery",
    icon: "log-out",
    component: PasswordRecoveryInfo,
  },
  {
    path: "/limit",
    sidebarName: "limite",
    navbarName: "limite",
    icon: "log-out",
    component: Limit,
  },
  {
    path: "/recoveryform",
    sidebarName: "recForm",
    navbarName: "recForm",
    icon: "log-out",
    component: PasswordRecoveryForm,
  },
];
