import React, { useState, useEffect } from "react";
import { optionalFn } from "../core/helpers";
import VisibilitySensor from "react-visibility-sensor";
import { GridContainer } from "./../components/Grid/Grid";

export function List({ page = 1, onLoad, onFail, load, by, format }) {
  const [list, setList] = useState([]);
  const [visible, setVisibility] = useState(load);
  const loadList = () => {
    if (!visible) {
      setList([]);
      return;
    }
    by.getter().then((response) => {
      if (response.code >= 300) {
        optionalFn(onFail)(response);
        return false;
      }
      //const { current_page, last_page } = response.content;
      optionalFn(onLoad)(response.content);
      setList(response.content.data);
    });
  };
  useEffect(loadList, [page, visible]);
  return (
    <VisibilitySensor
      partialVisibility
      onChange={(visibility) => {
        setVisibility(visibility);
      }}
    >
      <div
        style={{
          minHeight: list.length === 0 ? "50px" : "fit-content",
          width: "100%",
        }}
      >
        <GridContainer>
          {list.length ? list.map(format) : <h3>Vacio...</h3>}
        </GridContainer>
      </div>
    </VisibilitySensor>
  );
}
export function InfiniteList(props) {
  const [pages, setPages] = useState(0);
  const { defaultList, ...rest } = props;
  const printPages = (total) => {
    const pages = [];
    for (let index = 2; index <= total; index++) {
      pages.push(
        <props.defaultList page={index} {...rest} key={"page-" + index} />
      );
    }
    return pages;
  };
  return (
    <>
      <props.defaultList
        {...rest}
        load
        onLoad={(response) => {
          setPages(response.last_page);
        }}
      />
      {printPages(pages)}
    </>
  );
}
