export const User = {
  id: "",
  name: "",
  paterno: "",
  materno: "",
  email: "",
  telefono: "",
  whatsapp: "",
  username: "",
  picture_path: "",
  genero: "",
  perfil: "",
  record_status: "",
};
