import React from "react";
import { GridContainer, GridItem } from "../../components/Grid/Grid";
import "./scss/header.scss";
import logo from "../../assets/img/logos/logo.svg";
import { NavLink } from "react-router-dom";
import { DeskMenu, ConsumerMenu } from "./../menu/Menu";
import { ScreenRangeContainer } from "../../ESComponents/Blocks";
import { ReactSVG } from "react-svg";
import { SearchInput } from "./../../views/web/posts/SearchView";
export function Header() {
  return (
    <>
      <ScreenRangeContainer min={1001}>
        <DeskHeader />
      </ScreenRangeContainer>
      <ScreenRangeContainer max={1000}>
        <MobileHeader />
      </ScreenRangeContainer>
    </>
  );
}
function MobileHeader() {
  return (
    <header className="header">
      <GridContainer>
        <GridItem xs={12}>
          <NavLink to="/home">
            <ReactSVG src={logo} alt="Busco Amor" className={"logo"} />
          </NavLink>
        </GridItem>
      </GridContainer>
    </header>
  );
}
function DeskHeader() {
  return (
    <header className="header desk">
      <GridContainer>
        <GridItem xs={4} md={2} lg={1} className="logoContainer">
          <NavLink to="/home">
            <ReactSVG src={logo} alt="Busco Amor" className={"logo"} />
          </NavLink>
        </GridItem>
        <GridItem xs={8} md={3} className="searchContainer">
          <SearchInput />
        </GridItem>
        <GridItem xs={10} md={5} className="menuContainer">
          <DeskMenu />
        </GridItem>
        <GridItem xs={2} md={2} lg={3} className="consumerContainer">
          <ConsumerMenu />
        </GridItem>
      </GridContainer>
    </header>
  );
}
