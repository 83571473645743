import React from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Button } from "../../../ESComponents/Buttons";
import { SecundaryHero } from "../hero/Hero";
import { CheckPassword } from "../user/RegisterForm";
import { UsersController } from "./../../../utils/Controller/UsersController";
import { useState } from "react";
import { useQuery } from "../../../utils/hooks/simpleHooks";
import peach from "../../../assets/img/icons/svg/peach.svg";
import bg from "../../../assets/img/backgrounds/hero.jpg";
import { SplashScreen } from "./../../../ESComponents/SplashScreen";
export function ActivatedAccount() {
  return (
    <SecundaryHero title="Bienvenido...">
      <center style={{ fontSize: "1.4em" }}>
        Felicidades, ahora formas parte de la comunidad de buscamor, en la cual
        podrás publicar tus servicios y llegar a una audiencia perfecta para tu
        negocio. No lo pienses más dale clic al siguiente link, y deja que tu
        público te conozca.
        <NavLink to="/auth" style={{ color: "blue", margin: "2em" }}>
          <Button>Ingresar</Button>
        </NavLink>
      </center>
    </SecundaryHero>
  );
}
export function ResendActivaingMail() {
  return (
    <SecundaryHero title="Oops!">
      <center style={{ fontSize: "1.4em" }}>
        <div>
          ¡Parece que tu liga de activación ha caducado, no te preocupes, justo
          te acabamos de mandar una nueva, en solo unos minutos revisa tu correo
          ya debería de estar ahí!{" "}
        </div>
        <NavLink to="/auth" style={{ color: "blue", margin: "2em" }}>
          <Button>Ingresar</Button>
        </NavLink>
      </center>
    </SecundaryHero>
  );
}
export function PasswordRecoveryInfo() {
  const { mail } = useParams();
  return (
    <SecundaryHero title="Oops!">
      <center style={{ fontSize: "1.4em" }}>
        <div>
          ¡Parece que haz perdido tu contraseña, no te preocupes, justo te
          acabamos de mandar una nueva al correo {mail}, en solo unos minutos
          revisa tu correo para proceder con la recuperacion{" "}
        </div>
        <NavLink to="/auth" style={{ color: "blue", margin: "2em" }}>
          <Button>Ingresar</Button>
        </NavLink>
      </center>
    </SecundaryHero>
  );
}

export function Limit() {
  return (
    <SecundaryHero title="Oops!">
      <center style={{ fontSize: "1.4em" }}>
        <div>¡Parece que haz llegado a tu limite de anuncios por dia!!! </div>
      </center>
    </SecundaryHero>
  );
}
export function PasswordRecoveryForm() {
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(0);
  const { token } = useQuery();
  const history = useHistory();
  return (
    <>
      <SecundaryHero title="Tranquilo!">
        <div>
          <div>
            Solo ingresa tu nueva contraseña, y al instante estaras de nuevo en
            accion{" "}
          </div>
          <form
            onSubmit={(ev) => {
              ev.preventDefault();
              const user = new UsersController();

              if (!password) return false;
              setLoading(1);
              user.recovery({ token, pass: password }).then(() => {
                history.push("/auth");
              });
            }}>
            <CheckPassword
              onChange={(ev) => {
                setPassword(ev);
              }}></CheckPassword>
            <Button>Actualizar contraseña</Button>
          </form>
        </div>
      </SecundaryHero>
      <SplashScreen
        open={loading}
        msg="Cargando..."
        background={bg}
        icon={peach}
      />
    </>
  );
}
