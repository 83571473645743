import React from "react";
import { GridContainer, GridItem } from "../../components/Grid/Grid";
import { NavLink } from "react-router-dom";
export function Footer() {
  let year = new Date().getFullYear();
  return (
    <footer>
      <GridContainer>
        <GridItem xs={8}>
          Busco Amor no representa ni se hace responsable por lo aqui anunciado.
          | {year}
        </GridItem>
        <GridItem xs={4}>
          <NavLink to={"/privacy"}>Aviso de privacidad</NavLink>
        </GridItem>
      </GridContainer>
    </footer>
  );
}
