import React from "react";
import { Route, Redirect } from "react-router-dom";
import { routes } from "./../routes/adminRoutes";
import { CSSTransition } from "react-transition-group";
import { Header } from "./header/header";
import "./scss/main.scss";
import { MobileMenu } from "./menu/Menu";
import { ScreenRangeContainer } from "../ESComponents/Blocks";
import { Footer } from "./footer/footer";
export class MainContainer extends React.Component {
  handleRoutes = () => {
    return routes.map((prop, key) => {
      if (prop.redirect && this.props.location.pathname === prop.path) {
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      }
      if (!prop.component) {
        return null;
      }
      return (
        <Route path={prop.path} key={key}>
          {({ match }) => {
            window.scrollTo(0, 0);
            return (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="page"
                unmountOnExit
              >
                <div className="content page">
                  <prop.component />
                </div>
              </CSSTransition>
            );
          }}
        </Route>
      );
    });
  };
  render() {
    return (
      <>
        <Header></Header>
        <ScreenRangeContainer max={1000}>
          <MobileMenu />
        </ScreenRangeContainer>
        {this.handleRoutes()}
        <ScreenRangeContainer min={1001}>
          <Footer />
        </ScreenRangeContainer>
      </>
    );
  }
}
