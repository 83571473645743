import React from "react";
import { GradientButton } from "../../../components/Inputs/GradientButton";
import cameraIcon from "../../../assets/img/icons/peachCamera.png";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import "./scss/hero.scss";
import { NavLink } from "react-router-dom";
import { secondary } from "../../../vars/colors";
import { third } from "./../../../vars/colors";
import { LoginManager } from "./../../../utils/LoginManager";
import mainImage from "../../../assets/img/backgrounds/hero.jpg";
import { Image } from "./../../../components/Images/Image";
export function HeroSection({
  children,
  image = mainImage,
  path,
  className,
  buttonText,
}) {
  let logged = new LoginManager().isLogged();
  let link = logged ? "/register/post" : "/auth";
  return (
    <section className={`heroSection ${className}`}>
      <GridContainer>
        <GridItem xs={12} s={6} className="herotext">
          {children}
          <NavLink to={path || link}>
            <GradientButton
              colors={[
                { color: secondary, stop: "0" },
                { color: third, stop: "100" },
              ]}>
              <GridContainer>
                <GridItem
                  xs={3}
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                  }}>
                  <img src={cameraIcon} alt="peachCamera" />
                </GridItem>
                <GridItem
                  xs={9}
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                  }}>
                  <h2>{buttonText || "Publicar anuncio"}</h2>
                </GridItem>
              </GridContainer>
            </GradientButton>
          </NavLink>
        </GridItem>
        <GridItem xs={12} s={6} className="heroImage">
          <Image type="fullscreen" src={image}></Image>
        </GridItem>
      </GridContainer>
    </section>
  );
}
export function SecundaryHero({ children, title, image }) {
  return (
    <GridContainer className="secondHero">
      <GridItem className="banner">
        <Image src={image || mainImage}>
          <div className="overlay">
            <h1 style={{ fontSize: "5vw", width: "100%" }}>{title}</h1>
          </div>
        </Image>
      </GridItem>
      <GridItem className="content">{children}</GridItem>
    </GridContainer>
  );
}
