import React, { useState, useEffect } from "react";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import { SimpleInput } from "../../../components/Inputs/SimpleInput";
import { Textarea } from "../../../components/Inputs/Textarea";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { Post } from "./../../../utils/Models/Post";
import { PostsController } from "./../../../utils/Controller/PostsController";
import {
  LocationSelector,
  GenderSelector,
} from "../../../ESComponents/Selectors";
import { Button } from "./../../../ESComponents/Buttons";
import { SelectCategory } from "./../../../ESComponents/Selectors";
import phoneIMG from "../../../assets/img/icons/phone.png";
import mailIMG from "../../../assets/img/icons/mail.png";
import whatsIMG from "../../../assets/img/icons/whats.png";
import gpsIMG from "../../../assets/img/icons/gps.png";
import { H2 } from "../../../ESComponents/Titles";
import { PostValidator } from "./../../../utils/Validators/PostsValidator";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import peach from "../../../assets/img/icons/svg/peach.svg";
import bg from "../../../assets/img/backgrounds/hero.jpg";
import { SplashScreen } from "../../../ESComponents/SplashScreen";
import { SingleFile } from "./../../../ESComponents/Uploaders";
import { UsersController } from "../../../utils/Controller/UsersController";
import { optionalFn } from "../../../core/helpers";
import { ConditionalWall } from "../../../components/Filters/ConditionalWall";
import { LoginManager } from "./../../../utils/LoginManager";
export function PostRegisterForm() {
  const [state, setState] = useCState(Post);
  const [loading, setLoading] = useState(1);
  const [errors, setErrors] = useState({});
  const uc = new UsersController();
  const me = uc.me();
  const loadLimit = () => {
    uc.getLimit(me.id).then((response) => {
      if (response.code === 400) {
        history.push("/limit");
      } else {
        setLoading(0);
      }
    });
  };
  useEffect(loadLimit, []);
  let {
    fkcategoria,
    fkCiudad,
    fkEstado,
    correo,
    descripcion,
    nombre,
    precio,
    telefono,
    titulo,
    whatsapp,
    imagen_01,
    sitio_web,
    cedula,
  } = state;

  const history = useHistory();
  const profile = new UsersController().me().fkPerfil;

  const lm = new LoginManager();
  if (!lm.isLogged()) {
    history.push("/auth");
  }
  return (
    <>
      <SplashScreen
        open={loading}
        msg="Cargando..."
        background={bg}
        icon={peach}
      />
      <CSSTransition in={!loading} timeout={300} classNames="form">
        <form
          className={`form `}
          onSubmit={(ev) => {
            uploader(ev, state, setLoading, history, setErrors);
          }}>
          <GridContainer>
            <GridItem xs={12}>
              <H2>¡Presentate al mundo!</H2>
            </GridItem>
            <SelectCategory
              id="fkcategoria"
              profileID={me.fkPerfil}
              error={errors["fkcategoria"]}
              errMsg="Este campo es obligatorio"
              value={fkcategoria}
              name="fkcategoria"
              onChange={(data) => {
                setState({ fkcategoria: data.value });
              }}
            />
            <GridItem xs={12}>
              <SimpleInput
                id="titulo"
                error={errors["titulo"]}
                required
                onChange={({ target }) => {
                  setState({ titulo: target.value });
                }}
                value={titulo}
                title="Título"
              />
            </GridItem>
            <GridItem xs={12}>
              <Textarea
                title="Descripción"
                onChange={(value) => {
                  setState({ descripcion: value });
                }}
                value={descripcion}
                id="descripcion"
                error={errors["descripcion"]}
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                required
                onChange={({ target }) => {
                  setState({ precio: target.value });
                }}
                value={precio}
                id="precio"
                error={errors["precio"]}
                title="Precio"
                type="number"
              />
            </GridItem>
            <GridItem xs={12}>
              <H2>Veamos...</H2>
            </GridItem>
            <GridItem xs={4}>
              <SingleFile
                id="imagen_01"
                error={errors["imagen_01"]}
                title={"Subir Imagen"}
                onChange={(file) => {
                  setState({
                    imagen_01: file,
                  });
                }}
              />
            </GridItem>
            {imagen_01 ? (
              <>
                <GridItem xs={4}>
                  <SingleFile
                    id="imagen_02"
                    error={errors["imagen_02"]}
                    title={"Subir Imagen"}
                    onChange={(file) => {
                      setState({
                        imagen_02: file,
                      });
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <SingleFile
                    id="imagen_03"
                    error={errors["imagen_03"]}
                    title={"Subir Imagen"}
                    onChange={(file) => {
                      setState({
                        imagen_03: file,
                      });
                    }}
                  />
                </GridItem>
              </>
            ) : (
              ""
            )}

            <GridItem xs={12}>
              <IconTitle icon={gpsIMG} title="¿En donde te encuentras?" />
            </GridItem>
            <GridItem xs={12}>
              <LocationSelector
                state={fkEstado}
                city={fkCiudad}
                id="location"
                error={errors["fkCiudad"]}
                onChange={({ state, city }) => {
                  setState({ fkEstado: state, fkCiudad: city });
                }}
              />
            </GridItem>
            <ConditionalWall open={profile === 4}>
              <AddressInput
                onChange={(values) => {
                  setState(values);
                }}
              />
            </ConditionalWall>

            <GridItem xs={12}>
              <h2
                style={{
                  textAlign: "center",
                  color: "#777",
                }}>
                Hablanos de ti
              </h2>
            </GridItem>

            <GridItem xs={12}>
              <SimpleInput
                required
                onChange={({ target }) => {
                  setState({ nombre: target.value });
                }}
                value={nombre}
                id="nombre"
                error={errors["nombre"]}
                title="Nombre"
              />
            </GridItem>
            <GenderSelector
              id="fkGenero"
              error={errors["fkGenero"]}
              required
              onChange={(option) => {
                setState({ fkGenero: option.value });
              }}
            />
            <GridItem xs={6}>
              <SimpleInput
                required
                icon={whatsIMG}
                onChange={({ target }) => {
                  setState({ whatsapp: target.value });
                }}
                value={whatsapp}
                id="whatsapp"
                error={errors["whatsapp"]}
                title="Whatsapp"
                type="tel"
              />
            </GridItem>
            <GridItem xs={6}>
              <SimpleInput
                required
                onChange={({ target }) => {
                  setState({ telefono: target.value });
                }}
                value={telefono}
                id="telefono"
                error={errors["telefono"]}
                icon={phoneIMG}
                title="Teléfono"
                type="tel"
              />
            </GridItem>

            <GridItem xs={12}>
              <SimpleInput
                required
                icon={mailIMG}
                onChange={({ target }) => {
                  setState({ correo: target.value });
                }}
                value={correo}
                id="correo"
                error={errors["correo"]}
                title="E-mail"
                type="email"
              />
            </GridItem>
            <ConditionalWall open={profile === 4}>
              <GridItem xs={12}>
                <SimpleInput
                  required
                  onChange={({ target }) => {
                    setState({ sitio_web: target.value });
                  }}
                  value={sitio_web}
                  id="sitio_web"
                  error={errors["sitio_web"]}
                  title="Sitio Web"
                  type="url"
                />
              </GridItem>
              <GridItem xs={12}>
                <SimpleInput
                  required
                  onChange={({ target }) => {
                    setState({ cedula: target.value });
                  }}
                  value={cedula}
                  id="cedula"
                  error={errors["cedula"]}
                  title="Cedula"
                />
              </GridItem>
            </ConditionalWall>
            <GridItem xs={12}>
              <Button>
                <h2>Publicar</h2>
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </CSSTransition>
    </>
  );
}

async function uploader(ev, state, setLoading, history, setErrors) {
  ev.preventDefault();
  let anuncios = new PostsController();
  let pv = new PostValidator(state);
  let { valid, err } = pv.validate();
  if (valid) {
    setLoading(1);
    state.telefono = state.telefono.replace(/[^0-9.]/g, "");
    state.whatsapp = state.whatsapp.replace(/[^0-9.]/g, "");
    let images = {
      imagen_01: state.imagen_01,
      imagen_02: state.imagen_02,
      imagen_03: state.imagen_03,
    };
    delete state.imagen_01;
    delete state.imagen_02;
    delete state.imagen_03;
    let response = await anuncios.post(state);
    let { id } = await response.content;
    await anuncios.images(id, images);
    history.push(`/post/${id}`);
  } else {
    errorManager(err);
    setErrors(err);
    //setLoading(0);
  }
}

function errorManager(errors) {
  let item = Object.values(errors)[0];
  let target = item[0].target;
  swal(`Parece que hay algo mal con tus datos `);
  if (document.getElementById(target)) {
    document.getElementById(target).scrollIntoView();
  }
}

function IconTitle({ icon, title }) {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        alt="gps"
        src={icon}
        style={{ width: "40px", position: "relative", top: "10px" }}
      />
      <h2
        style={{
          textAlign: "center",
          display: "inline-block",
          color: "#777",
          fontSize: "1.4rem",
        }}>
        {title}
      </h2>
    </div>
  );
}

function AddressInput({ onChange }) {
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [colony, setColony] = useState("");
  return (
    <>
      <GridItem>
        <SimpleInput
          title="Calle"
          value={street}
          required
          onChange={({ target }) => {
            setStreet(target.value);
            optionalFn(onChange)({
              calle: street,
              numero: number,
              colonia: colony,
            });
          }}
        />
      </GridItem>
      <GridItem>
        <SimpleInput
          title="Numero"
          value={number}
          type="number"
          required
          onChange={({ target }) => {
            setNumber(target.value);
            optionalFn(onChange)({
              calle: street,
              numero: number,
              colonia: colony,
            });
          }}
        />
      </GridItem>
      <GridItem>
        <SimpleInput
          title="Colonia"
          value={colony}
          required
          onChange={({ target }) => {
            setColony(target.value);
            optionalFn(onChange)({
              calle: street,
              numero: number,
              colonia: colony,
            });
          }}
        />
      </GridItem>
    </>
  );
}
