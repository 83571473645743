import React, { useState, useEffect } from "react";
import { useCState, useQuery } from "./../../../utils/hooks/simpleHooks";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import { SimpleInput } from "../../../components/Inputs/SimpleInput";
import { Button } from "./../../../ESComponents/Buttons";
import { H2 } from "../../../ESComponents/Titles";
import { optionalFn, deleteDiacritics } from "../../../core/helpers";
import { UsersController } from "../../../utils/Controller/UsersController";
import { useHistory } from "react-router-dom";
import { SectionController } from "../../../utils/Controller/SectionsController";
import { Money } from "./../../../components/Formats/FormatNumbers";
import "./scss/Register.scss";
import { SingleFile } from "./../../../ESComponents/Uploaders";
import swal from "sweetalert";
import { SplashScreen } from "../../../ESComponents/SplashScreen";

import peach from "../../../assets/img/icons/svg/peach.svg";
import bg from "../../../assets/img/backgrounds/hero.jpg";
import { CSSTransition } from "react-transition-group";
import { ProfileSelector } from "../../../ESComponents/Selectors";
import { ConditionalWall } from "../../../components/Filters/ConditionalWall";
import { CuponController } from "./../../../utils/Controller/CuponController";
let userModel = {
  fkgenero: 1,
  fkperfil: 1,
  correo: "",
  nombre: "",
  paterno: " ",
  password: "",
  telefono: "",
  username: "",
  whatsapp: "",
  fkSeccion: "",
};

export function UserRegister({ section }) {
  const querys = useQuery();
  userModel.correo = querys.email;
  const history = useHistory();
  const [state, setState] = useCState(userModel);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(0);

  return (
    <>
      <SplashScreen
        open={loading}
        msg="Cargando..."
        background={bg}
        icon={peach}
      />
      <CSSTransition in={!loading} timeout={300} classNames="form">
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            setLoading(1);
            let user = new UsersController();
            state.fkplan = section.id;
            //https://stackoverflow.com/questions/4572194/regex-to-replace-everything-except-numbers-and-a-decimal-point
            state.telefono = state.telefono.replace(/[^0-9.]/g, "");
            state.whatsapp = state.whatsapp.replace(/[^0-9.]/g, "");
            // end of copy
            user.post(state).then(({ code, data, message }) => {
              setLoading(0);
              if (code === 200) {
                history.push({ pathname: "/activating", state: data.content });
              } else {
                setErrorMessage(message);
              }
            });
          }}>
          <GridContainer>
            <GridItem xs={12}>
              <H2>¡Un primer paso!</H2>
              <h5>{section.section}</h5>
            </GridItem>
            <GridItem xs={12}>
              <SingleFile
                id="imagen01"
                className="profileImage"
                title={"Subir Imagen"}
                onChange={(file) => {
                  setState({
                    imagen: file,
                  });
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <ProfileSelector
                value={state.fkperfil}
                onChange={(profile) => {
                  setState({ fkperfil: profile.value });
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                required
                name="correo"
                id="correo"
                errorStatus={errors["correo"]}
                errorMessage={errors["correo"]}
                value={state.correo}
                onChange={({ target }) => {
                  setState({ correo: target.value });
                }}
                title="Correo"
                type="email"
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                required
                name="username"
                id="username"
                value={state.username}
                onChange={({ target }) => {
                  setState({ username: target.value });
                }}
                title="Username"
              />
            </GridItem>
            <GridItem xs={12}>
              <CuponInput
                name="cupon"
                value={state.cupon}
                onChange={(target) => {
                  setState({ cupon: target.value });
                }}
                title="Cupon"
              />
            </GridItem>
            <GridItem xs={12}>
              <H2>¿Quien eres?</H2>
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                required
                name="nombre"
                value={state.nombre}
                onChange={({ target }) => {
                  setState({ nombre: target.value });
                }}
                title="Nombre"
              />
            </GridItem>
            <GridItem xs={12}>
              <H2>Ayudanos a contactarte</H2>
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                required
                name="telefono"
                type="tel"
                value={state.telefono}
                onChange={({ target }) => {
                  setState({ telefono: target.value });
                }}
                title="Teléfono"
              />
            </GridItem>
            <GridItem xs={12}>
              <SimpleInput
                required
                name="whatsapp"
                type="tel"
                value={state.whatsapp}
                onChange={({ target }) => {
                  setState({ whatsapp: target.value });
                }}
                title="Whatsapp"
              />
            </GridItem>
            <GridItem xs={12}>
              <H2>¡La seguridad es fundamental!</H2>
            </GridItem>
            <CheckPassword
              onChange={(password) => {
                setState({ password });
              }}
            />

            <GridItem>
              <Button>Registrate</Button>
            </GridItem>
          </GridContainer>
        </form>
      </CSSTransition>
    </>
  );

  function setErrorMessage(message) {
    let errMsg = Object.values(message)[0][0];
    let target = Object.keys(message)[0];
    if (document.getElementById(target)) {
      document.getElementById(target).scrollIntoView();
    }
    setErrors(message);
    swal(errMsg);
  }
}

/**
 * Valida que las contraseñas coincidan.
 * @param {onChange,password} param0
 */
export function CheckPassword({ onChange }) {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(0);
  useEffect(() => {
    if (password === password2) {
      setError(0);
      optionalFn(onChange)(password);
    } else {
      setError(1);
    }
    // eslint-disable-next-line
  }, [password, password2]);
  return (
    <>
      <GridItem xs={12}>
        <SimpleInput
          required
          name="password"
          value={password}
          onChange={({ target }) => {
            setPassword(target.value);
          }}
          type="password"
          title="Contraseña"
        />
      </GridItem>
      <GridItem xs={12}>
        <SimpleInput
          required
          errorStatus={error}
          errorMessage={"contraseñas no coinciden"}
          name="confirmPassword"
          value={password2}
          onChange={({ target }) => {
            setPassword2(target.value);
          }}
          type="password"
          title="Confirma contraseña"
        />
      </GridItem>
    </>
  );
}
/**
 * Permite seleccionar un paquete de las opciones disponibles
 * @param {onSelected} param0
 */
export function SectionSelector({ onSelected }) {
  const [sections, setSection] = useState([]);
  const loadSections = () => {
    let sections = new SectionController();
    sections.get().then(({ data }) => {
      setSection(data.content);
    });
  };
  useEffect(() => {
    loadSections();
  }, []);
  return (
    <>
      <GridContainer className={"Sections"}>
        <GridItem xs={12}>
          <h2>Paquetes</h2>
        </GridItem>
        {sections.map(
          (
            { plan, precio, periodo, descripcion, subidas_diarias, id },
            key,
          ) => (
            <GridItem
              s={6}
              md={3}
              key={plan}
              className={`package ${deleteDiacritics(plan).toLowerCase()}`}>
              <Package
                section={plan}
                id={id}
                desc={descripcion}
                price={precio}
                days={periodo}
                uploads={subidas_diarias}
                onClick={onSelected}
              />
            </GridItem>
          ),
        )}
      </GridContainer>
    </>
  );
}
/**
 * Formatea informacion del paquete.
 * @param {} param0
 */
export function Package({
  id,
  section,
  price = 0,
  days = 0,
  uploads = 0,
  desc,
  onClick,
}) {
  return (
    <>
      <h4 className={"packName"}>{section}</h4>
      <Money number={price} className="money" />

      <ul>
        <li>{days} dias</li>
        <li>{uploads} subidas por dia</li>
        <li className="desc">{desc}</li>
      </ul>
      <ConditionalWall open={Boolean(onClick)}>
        <Button
          onClick={() => {
            optionalFn(onClick)({ id, section });
          }}>
          Contratar
        </Button>
      </ConditionalWall>
    </>
  );
}
/**
 * vincula el selector de paquetes con el formulario de registro
 */
export function RegisterWizard() {
  const [section, setSection] = useState(null);
  if (!section) {
    return (
      <SectionSelector
        onSelected={(content) => {
          setSection(content);
        }}
      />
    );
  }
  return <UserRegister section={section} />;
}

export function CuponInput({ value, onChange, ...rest }) {
  const [error, setError] = useState(0);
  let bError = Boolean(error);
  return (
    <SimpleInput
      errorStatus={bError}
      errorMessage={"Cupon no valido"}
      onBlur={({ target }) => {
        const fetch = new CuponController();
        fetch.validate(target.value).then((response) => {
          setError(response.code > 200);
        });
        optionalFn(onChange)(target);
      }}
      {...rest}
    />
  );
}
