import React from "react";
import { GridContainer, GridItem } from "../components/Grid/Grid";
import "./scss/skeleton.scss";
export function SkeletonPostPreview({ size }) {
  return (
    <GridContainer className="previewPost skeletonPost">
      <GridItem xs={5} className="imgShell waiting"></GridItem>
      <GridItem xs={7} className="descSkeleton">
        <div className="waiting"></div>
        <div className="waiting"></div>
      </GridItem>
    </GridContainer>
  );
}
export function SkeletonPost() {
  return (
    <GridContainer className="singularContainer skeletonSingularPost">
      <GridItem className="imgShell waiting"></GridItem>
      <GridItem xs={12} className="descSkeleton">
        <SkeletonRepeater repeats={5}>
          <div className="waiting"></div>
        </SkeletonRepeater>
      </GridItem>
    </GridContainer>
  );
}
export function SkeletonRepeater({ children, repeats }) {
  let arr = [];
  for (let index = 0; index < repeats; index++) {
    arr.push(children);
  }
  return arr;
}
