import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import { Button } from "./Buttons";
import { GridContainer, GridItem } from "../components/Grid/Grid";
import { filesToB64, optionalFn } from "./../core/helpers";
import { Image } from "../components/Images/Image";
import { FontIcon } from "./../components/Icons/FontIcon";
import "./scss/esComponents.scss";
export function UploaderButton({ title, onChange, error, id }) {
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(0);
  const [errorMessage, setMessage] = useState(
    "Se requiere minimo una imagen y maximo 3",
  );
  return (
    <div id={id} className={error ? "error" : ""}>
      <PreviewGrid files={files}></PreviewGrid>
      <Button type="button">
        <label htmlFor="images">
          <h2>{status ? "Cargando..." : title}</h2>
        </label>
      </Button>
      <input
        type="file"
        multiple
        id="images"
        name="images"
        accept="image/png, image/jpeg"
        onChange={({ target }) => {
          setStatus(1);
          if (target.files.length > 3) {
            setStatus(0);
            setMessage("Se requiere minimo una imagen y maximo 3");
            return false;
          }
          optionalFn(onChange)(target.files);
          filesToB64(target.files).then((files) => {
            setStatus(0);
            setFiles(files);
          });
        }}
        className="hidden"
      />
      {error ? (
        <p className="errorMessage" style={{ textAlign: "center" }}>
          {errorMessage}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}
export function PreviewGrid({ files }) {
  return (
    <GridContainer>
      {files.map(({ target }, key) => (
        <GridItem xs={4} key={key}>
          <Image
            src={target.result}
            type="thumb"
            style={{
              width: "100px",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundColor: "black",
              height: "100px",
              margin: "10px",
            }}
          />
        </GridItem>
      ))}
    </GridContainer>
  );
}
export function SingleFile({ title, onChange, error, id, className = "" }) {
  const [img, setImage] = useState(null);

  return (
    <div className={`singleFile square ${className}`}>
      <label htmlFor={id}>
        <div className={`imageContainer ${error ? "error" : ""}`}>
          <div className={!img ? "imageButton" : "hidden"}>
            <FontIcon icon="plus" />
            {title}
            {error ? (
              <p className="errorMessage" style={{ textAlign: "center" }}>
                "Esta imagen es requerida"
              </p>
            ) : (
              ""
            )}
          </div>
          <Image src={img} style={{ height: "150px" }} />
        </div>
      </label>
      <input
        type="file"
        name={id}
        id={id}
        className="hidden"
        accept="image/png, image/jpeg"
        onChange={({ target }) => {
          const options = {
            maxSizeMB: 0.6,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          imageCompression(target.files[0], options).then((img) => {
            optionalFn(onChange)(
              new File([img], "f.jpg", {
                type: "image/jpg",
                lastModified: new Date(),
              }),
            );
          });

          filesToB64(target.files).then((files) => {
            setImage(files[0].target.result);
          });
        }}
      />
    </div>
  );
}
