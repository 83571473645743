import React, { useEffect } from "react";
import { SecundaryHero } from "../hero/Hero";
import hero3 from "../../../assets/img/backgrounds/postGirl.jpg";
import { useLocation } from "react-router-dom";
import { SectionController } from "./../../../utils/Controller/SectionsController";
import { useCState } from "./../../../utils/hooks/simpleHooks";
import { Package } from "./RegisterForm";
import { GridContainer, GridItem } from "./../../../components/Grid/Grid";
import { deleteDiacritics } from "../../../core/helpers";
import "./scss/Register.scss";
import { Image } from "../../../components/Images/Image";
import bank from "../../../assets/img/logos/bank.png";
import whats from "../../../assets/img/icons/svg/whatsapp-brands.svg";
import { LinkButton } from "../../../ESComponents/Buttons";
export function ActivatePage() {
  let { state } = useLocation();
  if (state == null) {
    state = [{ fkplan: 1, monto: 0 }];
  }
  const { fkplan = 1, monto = 0 } = state[0];
  return (
    <SecundaryHero title="Saludos" image={hero3}>
      <center>
        <h1>Ha sido un registro fantabuloso!</h1>
        <SingularPlan id={fkplan} amount={monto} />
        <p style={{ marginTop: "10em" }}>
          En estos momentos, un correo deberia haber llegado a tu bandeja de
          entrada para verificar tu cuenta.
        </p>
        <h3>Muchas gracias por unirte a la familia BuscAmor</h3>
        <h1>Sera una delicia tenerte con nosotros.</h1>
      </center>
    </SecundaryHero>
  );
}

function SingularPlan({ id = 1, amount = 0 }) {
  const [state, setState] = useCState({
    plan: "",
    precio: "",
    periodo: "",
    descripcion: "",
    subidas_diarias: "",
  });
  const loadPlan = () => {
    const fetch = new SectionController();
    fetch.show(id).then((response) => {
      setState(response.data.content[0]);
    });
  };

  useEffect(loadPlan, [id]);
  const { plan, periodo, descripcion, subidas_diarias } = state;
  return (
    <GridContainer className="Sections" style={{ width: "100%" }}>
      <GridItem
        md={6}
        style={{
          margin: "0 auto",
          width: "300px",
          maxWidth: "87%",
        }}
        className={`package ${deleteDiacritics(plan).toLowerCase()}`}>
        <Package
          section={plan}
          id={id}
          desc={descripcion}
          price={amount}
          days={periodo}
          uploads={subidas_diarias}
        />
      </GridItem>
      <GridItem md={6} style={{ perspective: "1000px" }}>
        <h2 className="titulo">Datos de pago</h2>
        <PaymentCard />
        <div style={{ margin: "15px", fontWeight: "bolder" }}>
          Una vez hecho el pago, favor de notificarlo vía whatsApp al siguiente
          número:
          <LinkButton icon={whats} to={`tel:3325805895`}>
            33 25 80 58 95
          </LinkButton>
        </div>
      </GridItem>
    </GridContainer>
  );
}
function PaymentCard() {
  return (
    <div className="creditCard">
      <GridContainer className="info">
        <GridItem>
          <Image src={bank} />
        </GridItem>
        <GridItem className="cardNumber">5204 1655 3738 7890</GridItem>
        <GridItem className="refNumber">Referencia: BUSCAM114XARK</GridItem>
      </GridContainer>
    </div>
  );
}
