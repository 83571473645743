import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { GridContainer, GridItem } from "./../../../components/Grid/Grid";
import { Image } from "./../../../components/Images/Image";
import { Money } from "./../../../components/Formats/FormatNumbers";
import { AnalyticsController } from "./../../../utils/Controller/AnalyticsController";
import ReactHtmlParser from "react-html-parser";

export function PostPreview({
  id,
  image,
  title,
  city,
  state,
  price,
  date,
  desc,
  type = "basic",
}) {
  return (
    <NavLink to={`/post/${id}`} className={`post`}>
      <div
        className={`previewPost ${type}`}
        style={{
          backgroundImage: `url(https://${image})`,
          height: "412px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "flex-end",
          flexFlow: "column",
        }}>
        <PostContent
          title={title}
          place={`${city}, ${state}`}
          date={date}
          desc={desc}
          money={price}
        />
      </div>
    </NavLink>
  );
}

export function MedicalPostPreview({
  image,
  fullname,
  address,
  desc,
  id,
  url,
  cedula,
  type = "basic",
}) {
  const history = useHistory();
  return (
    <GridContainer
      className="medicalAd"
      onClick={() => {
        if (url) {
          const view = new AnalyticsController();
          view.post({ fkAnuncio: id }).then(() => {
            window.open(url, "_blank");
          });
        } else {
          history.push(`/post/${id}`);
        }
      }}>
      <GridItem xs={12} className="mainImage">
        <Image alt={fullname} src={image} />
      </GridItem>
      <GridItem xs={12} className="content">
        <div className="name">{fullname}</div>
        <div className="desc">Cedula:{cedula}</div>
        <div className="address">{address}</div>
      </GridItem>
    </GridContainer>
  );
}
export function PostContent({ title, place, date, desc, money }) {
  return (
    <GridContainer className="postContent">
      <GridItem xs={9}>
        <GridContainer>
          <GridItem xs={12}>
            <h3 className="title">{title.slice(0, 40)}</h3>
          </GridItem>
          <GridItem xs={6}>{place}</GridItem>
          <GridItem xs={6}>
            {new Date(date).toLocaleDateString("es-MX")}
          </GridItem>
          <GridItem xs={12} className="desc">
            {ReactHtmlParser(desc.slice(0, 80) + "...")}
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={3} className="money">
        <Money number={money}></Money>
      </GridItem>
    </GridContainer>
  );
}

/*function extraPost() {
  return (
    <>
      <GridItem
        xs={12}
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-end",
        }}
      >
        <GridContainer className="postContent">
          <GridItem className="title" xs={12}>
            <h3>{title}</h3>
          </GridItem>

          <GridItem xs={4} className="place">{`${city}, ${state}`}</GridItem>

          <GridItem xs={4} className="date">
            {localeDate(date)}
          </GridItem>
          <GridItem className="money" xs={4}>
            <Money number={price} />
          </GridItem>
        </GridContainer>
      </GridItem>
    </>
  );
}
*/
