import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import "./scss/blocks.scss";
import { GridItem } from "../components/Grid/Grid";
import { between } from "./../core/helpers";
export function Block({ style, children, ...rest }) {
  return (
    <div {...rest} style={{ minHeight: "5vh", ...style }}>
      {children}
    </div>
  );
}
export function ErrorBlock({ className, children, open, ...rest }) {
  return (
    <Block {...rest} className={`${open ? "errorBlock" : ""} ${className}`}>
      {open ? children : ""}
    </Block>
  );
}
export function ErrorGroup({ children, open, msg }) {
  return (
    <>
      {children}
      {open ? (
        <GridItem xs={12} className="errorMessage">
          {msg}
        </GridItem>
      ) : (
        ""
      )}
    </>
  );
}
export function LazyBlock({ onVisible, children }) {
  return (
    <VisibilitySensor partialVisibility onChange={onVisible}>
      <div className="vizsen">{children}</div>
    </VisibilitySensor>
  );
}
export function ScreenRangeContainer({ min = 0, max = Infinity, children }) {
  const [size, setSize] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setSize(window.innerWidth));
  }, []);
  if (between(size, min, max)) {
    return <>{children}</>;
  }
  return <></>;
}
