
import { BaseController } from './BaseController';
import { AuthFetch } from './../AuthFetch';
export class AnalyticsController extends BaseController {
  path = "v2.0/clicks";
  userPath ="v2.0/usuarios/reportes/registro"
  getByUser(userID) { 
    const fetch = new AuthFetch(`${this.path}/usuarios/${userID}`);
    return fetch.get();
  }
 
  getByPost(id) { 
    const fetch = new AuthFetch(`${this.path}/${id}`);
    return fetch.get();
  }
}
