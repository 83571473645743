import React from "react";
import { GridContainer } from "../../../components/Grid/Grid";
import { UserChart } from "../../../ESComponents/AnaliticsChart";

export function Sandbox() {
  return (
    <>
      <GridContainer>
        <UserChart />
      </GridContainer>
    </>
  );
}
