import React from "react";
import { GradientButton } from "../components/Inputs/GradientButton";
import { GridContainer, GridItem } from "./../components/Grid/Grid";
import "./scss/esComponents.scss";
import { ReactSVG } from "react-svg";
export function Button({ children, ...rest }) {
  return (
    <GradientButton
      {...rest}
      colors={[
        { color: "#f54f6b", stop: "0" },
        { color: "#d756b0", stop: "100" },
      ]}
    >
      {children}
    </GradientButton>
  );
}
export function SecondaryButton({ children, ...rest }) {
  return (
    <GradientButton className="secondaryButton" {...rest}>
      {children}
    </GradientButton>
  );
}
export function LinkButton({ to, icon, children, className = "", ...rest }) {
  return (
    <a href={to} className={`linkButton gradientButton ${className}`} {...rest}>
      <GridContainer>
        <GridItem xs={3} className="imgGrid">
          <ReactSVG className="svgIcon" src={icon} alt="link" />
        </GridItem>
        <GridItem xs={9}>{children}</GridItem>
      </GridContainer>
    </a>
  );
}
export function ThirdButton({ children, ...rest }) {
  return (
    <div {...rest} className="thirdButton">
      {children}
    </div>
  );
}
