import React, { useEffect, useState } from "react";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import { Money } from "../../../components/Formats/FormatNumbers";
import { Post } from "../../../utils/Models/Post";
import { PostsController } from "../../../utils/Controller/PostsController";
import "./scss/posts.scss";
import ReactHtmlParser from "react-html-parser";

import whats from "../../../assets/img/icons/svg/whatsapp-brands.svg";
import tel from "../../../assets/img/icons/svg/phone-solid.svg";
import mail from "../../../assets/img/icons/svg/envelope-solid.svg";
import { LinkButton } from "../../../ESComponents/Buttons";
import { useHistory, useParams } from "react-router-dom";
import { SkeletonPost } from "../../../ESComponents/Skeleton";
import { Carrousel } from "./../../../components/container/Carrousel";
import { Image } from "./../../../components/Images/Image";
import VisibilitySensor from "react-visibility-sensor";
import { ConditionalWall } from "../../../components/Filters/ConditionalWall";
import { UsersController } from "./../../../utils/Controller/UsersController";
import { PostChart } from "./../../../ESComponents/AnaliticsChart";
import { PostsByRecommendation } from "./PostsViews";
export function SingularPost() {
  let [post, setPost] = useState(Post);
  const [loading, setLoading] = useState(1);
  const [carVisible, setVisible] = useState(1);
  const { id } = useParams();
  const history = useHistory();
  let {
    nombre,
    ciudad,
    estado,
    titulo,
    precio,
    descripcion,
    whatsapp,
    telefono,
    imagenes,
    genero,
    correo,
    categoria,
    create_user,
  } = post;
  const loadPost = (id) => {
    setLoading(1);
    const post = new PostsController();
    post.show(id).then((response) => {
      if (response.code > 200) {
        history.push("/home");
        return false;
      }
      setPost(response.data.content[0]);
      setLoading(0);
    });
  };
  useEffect(() => {
    loadPost(id);
    // eslint-disable-next-line
  }, [id]);
  let place = `Soy ${nombre} de ${ciudad}, ${estado}`;

  window.scrollTo(0, 0);
  const me = new UsersController().me();
  if (loading) return <SkeletonPost />;
  return (
    <GridContainer className="singularContainer">
      <GridItem xs={12} md={5} className="head">
        <VisibilitySensor
          onChange={(visible) => {
            setVisible(visible);
          }}>
          <Carrousel autoRun={carVisible}>
            {imagenes.map((item, key) => (
              <Image
                type="Gallery"
                alt={titulo}
                key={key}
                src={"https://" + item}
              />
            ))}
          </Carrousel>
        </VisibilitySensor>
      </GridItem>
      <GridItem xs={12} md={7}>
        <GridContainer className={"singularInfoContainer"}>
          <GridItem xs={12} className>
            <h1>{titulo}</h1>
          </GridItem>
          <GridItem xs={12} className="place">
            {place}
          </GridItem>
          <ConditionalWall open={me.id === create_user}>
            <GridItem xs={12} className="place">
              <div style={{ height: "300px", margin: "10px" }}>
                <PostChart id={id} />
              </div>
            </GridItem>
          </ConditionalWall>
          <GridItem xs={12} className="gender">
            <strong>Genero: </strong>
            <span>{genero}</span>
          </GridItem>
          <GridItem xs={12} className="desc">
            <strong>Descripción: </strong>
            <div>{ReactHtmlParser(decodeURI(descripcion))}</div>
          </GridItem>
          <GridItem xs={12} className="price">
            <strong>Donación: </strong>
            <Money number={precio} />
          </GridItem>
          <GridItem xs={12}>
            <Disclaimer />
          </GridItem>
          <GridItem s={4}>
            <LinkButton icon={whats} to={`tel:${whatsapp}`}>
              {whatsapp}
            </LinkButton>
          </GridItem>
          <GridItem s={4}>
            <LinkButton icon={tel} to={`tel:${telefono}`}>
              {telefono}
            </LinkButton>
          </GridItem>
          <GridItem s={4}>
            <LinkButton icon={mail} to={`mailTo:${correo}`}>
              Contáctame
            </LinkButton>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <h3 className={"catTitle"}>{categoria}</h3>

        <PostsByRecommendation id={id} perPage={3} />
      </GridItem>
    </GridContainer>
  );
}
function Disclaimer() {
  return (
    <div className="disclaimer">
      <strong>Mis Términos</strong>
      <p>
        Tu donación es por mi servicio legal de acompañante o masajista y no por
        sexo, si llegara a pasar algo más entre nosotros, seria porque somos
        personas legalmente adultas y libres de practicar nuestra sensualidad y
        sexualidad
      </p>
    </div>
  );
}
/*function Disclaimer2() {
  return (
    <div className="disclaimer baseline">
      Esta app no se hace responsable por lo aqui anunciado.
    </div>
  );
}*/
