import React, { useState, useEffect } from "react";
import { optionalFn } from "../../core/helpers";
import { FontIcon } from "../Icons/FontIcon";
import "./scss/inputs.scss";
import { GridContainer, GridItem } from "../Grid/Grid";
/*eslint eqeqeq: 0*/
export function SimpleInput({
  title,
  required,
  onBlur,
  message,
  icon,
  placeholder = "Llena el campo",
  errorMessage = "Campo invalido",
  errorStatus = 0,
  children,
  ...rest
}) {
  const [error, setError] = useState(0);
  useEffect(() => {
    setError(errorStatus);
  }, [errorStatus]);
  let err = error || errorStatus;
  return (
    <div className={`simpleInput ${err ? "error" : ""}`}>
      <LabelIcon
        icon={icon}
        title={title || ""}
        required={required}
        message={message}
      />
      <input
        placeholder={placeholder}
        onBlur={(ev) => {
          optionalFn(onBlur)(ev);
          if (ev.target.value == "") {
            return "";
          }
          if (!ev.target.checkValidity()) {
            setError(1);
          } else {
            setError(0);
          }
        }}
        required={required}
        {...rest}
      />
      {children}
      {err ? <p className="errorMessage">{errorMessage}</p> : ""}
      <p className="placeholder">{placeholder}</p>
    </div>
  );
}
function LabelIcon({ icon, title, required, message }) {
  if (!icon) {
    return (
      <label>
        {` ${title} ${required ? "*" : ""}`}
        <HelpInput message={message} />
      </label>
    );
  }
  return (
    <GridContainer className={"iconLabel"}>
      <GridItem xs={2}>
        <img src={icon} style={{ width: "33px" }} alt={title} />
      </GridItem>
      <GridItem xs={10}>
        <label>
          {` ${title} ${required ? "*" : ""}`}
          <HelpInput message={message} />
        </label>
      </GridItem>
    </GridContainer>
  );
}

export function FormatSimpleInput({
  title = "input",
  required,
  format,
  value,
  onBlur,
  message,
  ...rest
}) {
  const [editable, setEditable] = useState(0);
  return (
    <div className="simpleInput format">
      <label>
        {`${title} ${required ? "*" : ""}`}
        <HelpInput message={message} />
      </label>
      <input
        autoFocus={editable}
        required={required}
        value={value}
        style={{
          opacity: editable ? 1 : 0,
          position: editable ? "relative" : "fixed",
          top: !editable ? "0" : "inherit",
        }}
        {...rest}
        onFocus={() => {
          setEditable(1);
        }}
        onBlur={(ev) => {
          optionalFn(onBlur)(ev);
          setEditable(0);
        }}
      />
      {!editable && (
        <div
          onClick={() => {
            setEditable(1);
          }}
        >
          {format}
        </div>
      )}
    </div>
  );
}
export function HelpInput({ message }) {
  const [show, toggle] = useState(0);
  if (!message) {
    return "";
  }
  return (
    <span className="helpingMessage">
      <span
        className="question"
        onMouseEnter={(ev) => {
          toggle(1);
        }}
        onMouseLeave={() => {
          toggle(0);
        }}
      >
        <FontIcon icon="question-circle" />
      </span>
      {show ? <span className="helper">{message}</span> : ""}
    </span>
  );
}
export function IconicInput({ icon = "search", id, title, onClick, ...rest }) {
  if (typeof icon == "string") {
    icon = <FontIcon icon={icon} onClick={onClick} />;
  }
  return (
    <div className="iconicInput">
      <label htmlFor={id}>{title}</label>
      <GridContainer>
        <GridItem xs={1} className="icon">
          {icon}
        </GridItem>
        <GridItem xs={11}>
          <input id={id} {...rest} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
