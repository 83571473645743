import { BaseController } from "./BaseController";
import { AuthFetch } from "./../AuthFetch";

export class CuponController extends BaseController {
  path = "v2.0/cupones";
  validate(cupon) {
    const fetch = new AuthFetch(`${this.path}/${cupon}/validate`);
    return fetch.get();
  }
}
