import React, { useEffect, useState } from "react";
import { HeroSection } from "../../web/hero/Hero";
import "./scss/home.scss";

import { PostsByCategory } from "../../web/posts/PostsViews";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import { CategoriesSelector } from "../../web/categories/CategoriesComponents";
import { ScreenRangeContainer } from "../../../ESComponents/Blocks";
import { MedicalPosts } from "./../../web/posts/PostsViews";
import { Image } from "./../../../components/Images/Image";
import { NavLink } from "react-router-dom";
import postGirl from "../../../assets/img/backgrounds/postGirl.jpg";
import sexShop from "../../../assets/img/backgrounds/sexshop.jpg";
import surgeon from "../../../assets/img/backgrounds/Surgeon.jpg";
import { PostsController } from "./../../../utils/Controller/PostsController";
import ReactHtmlParser from "react-html-parser";
export function HomeContainer() {
  return (
    <>
      <HeroPop />
      <GridContainer className="mainTopics">
        <GridItem>
          <h2
            style={{
              padding: "0 5vw",
            }}>
            Mil y un experiencias esperando por ti...
          </h2>
        </GridItem>
        <GridItem md={4}>
          <ProfileCard
            img={postGirl}
            to={"/escorts"}
            title="Escorts"
            desc="Las mejores acompañantes tan cerca de ti."
          />
        </GridItem>
        <GridItem md={4}>
          <ProfileCard
            img={sexShop}
            to={"/sexshop"}
            title="Sex shops"
            desc="Un sin fin de Tiendas Eróticas de confianza"
          />
        </GridItem>
        <GridItem md={4}>
          <ProfileCard
            img={surgeon}
            to={"/medicos"}
            title="Servicios Medicos"
            desc="Si la vida te da limones..."
          />
        </GridItem>
      </GridContainer>
      <HeroPosts />
    </>
  );
}
export function HeroPosts() {
  const [cat, setCat] = useState({ label: "todos", value: 0 });
  return (
    <GridContainer className={"heroPost"}>
      <GridItem xs={12}>
        <CategoriesSelector
          onChange={(ev) => {
            setCat(ev);
          }}
        />
        <hr />
      </GridItem>
      <GridItem xs={12}>
        <h3 className={"catTitle"}>{cat.label.toUpperCase()}</h3>
      </GridItem>
      <GridItem xs={12} md={8} lg={10}>
        <PostsByCategory categoryID={cat.value} />
      </GridItem>
      <GridItem md={4} lg={2}>
        <ScreenRangeContainer min={1220}>
          <MedicalPosts />
        </ScreenRangeContainer>
      </GridItem>
    </GridContainer>
  );
}
export function ProfileCard({ title, desc, img, to }) {
  return (
    <NavLink to={to}>
      <GridContainer className="profileCard">
        <GridItem className="imgContainer">
          <Image src={img} />
        </GridItem>
        <GridItem className="dataContent">
          <GridContainer>
            <GridItem className="title">{title}</GridItem>
            <GridItem>{desc}</GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </NavLink>
  );
}
export function HeroPop() {
  const [pop, setPop] = useState({
    titulo: " Yo soy Vanessa.",
    descripcion: "Y al igual que muchos otros, estoy ansiosa por conocerte.",
    imagenes: [],
  });
  const load = () => {
    const fetch = new PostsController();
    fetch.getPop(1).then((response) => {
      if (response.content.data.length === 0) {
        return false;
      }
      setPop(response.content.data[0]);
    });
  };
  useEffect(load, []);

  return (
    <HeroSection
      image={pop.imagenes[0] ? `https://${pop.imagenes[0]}` : undefined}
      path={pop.id ? `/post/${pop.id}` : undefined}
      buttonText={pop.id ? "Ver mas" : undefined}
      className={pop.id ? "custom" : undefined}>
      <h2 style={{ color: "#444" }} className="heroTitle">
        {pop.titulo}
      </h2>
      {ReactHtmlParser(pop.descripcion.slice(0, 80) + "...")}
    </HeroSection>
  );
}
