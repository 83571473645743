import React from "react";
import { Image } from "./../../../components/Images/Image";
import { UsersController } from "./../../../utils/Controller/UsersController";
import { NavLink } from "react-router-dom";
export function UserButton() {
  let { username, picture_path } = new UsersController().me();
  return (
    <div className="userButton">
      <NavLink to={"/account"}>
        <Image
          type="profile"
          src={
            picture_path
              ? "https://" + picture_path
              : "https://picsum.photos/50/50"
          }
        />
        <h3 className="username">{username}</h3>
      </NavLink>
    </div>
  );
}
