import React, { useState } from "react";
import { GridContainer, GridItem } from "../../../components/Grid/Grid";
import { PostsController } from "./../../../utils/Controller/PostsController";
import { deleteDiacritics } from "../../../core/helpers";
import { UsersController } from "./../../../utils/Controller/UsersController";
import { List, InfiniteList } from "./../../../ESComponents/Lists";
import { CategoriesSelector } from "../categories/CategoriesComponents";
import ReactHtmlParser from "react-html-parser";
import { Button } from "../../../ESComponents/Buttons";
import { MedicalPostPreview, PostPreview } from "./PostPreviews";
import "./scss/posts.scss";
import { NavLink } from "react-router-dom";
import { SecundaryHero } from "../hero/Hero";
import news from "../../../assets/img/backgrounds/news.jpg";
import girl from "../../../assets/img/backgrounds/girl2.jpg";
import { UserChart } from "../../../ESComponents/AnaliticsChart";

export function PostsList({ page = 1, load = true, onLoad, onFail, loadBy }) {
  return (
    <List
      page={page}
      onLoad={onLoad}
      load={load}
      onFail={onFail}
      by={{
        getter: () => {
          return loadBy();
        },
      }}
      format={(item) => (
        <GridItem key={"id-" + item.id} xs={12} s={6} lg={4} xl={3}>
          <PostPreview
            id={item.id}
            image={loadImage(item.imagenes)}
            title={item.titulo}
            price={item.precio}
            city={item.ciudad}
            state={item.estado}
            desc={item.descripcion}
            date={item.fecha_creacion}
            categoria={item.categoria}
            userData={item.user_create}
            type={deleteDiacritics(item.plan).toLowerCase()}
          />
        </GridItem>
      )}
    />
  );
}
export function SidebarPostList({
  page = 1,
  load = true,
  onLoad,
  loadBy,
  onFail,
}) {
  return (
    <List
      page={page}
      onLoad={onLoad}
      load={load}
      onFail={onFail}
      by={{
        getter: () => {
          return loadBy();
        },
      }}
      format={(item) => (
        <GridItem key={"id-" + item.id} xs={12}>
          <MedicalPostPreview
            fullname={item.nombre}
            url={item.sitio_web}
            address={`${item.calle} ${item.numero}, ${item.ciudad}, ${item.estado}`}
            id={item.id}
            image={"https://" + loadImage(item.imagenes)}
            desc={ReactHtmlParser(item.descripcion)}
            type={deleteDiacritics(item.plan).toLowerCase()}
            cedula={item.cedula}
          />
        </GridItem>
      )}
    />
  );
}
function loadImage(img) {
  let path = "picsum.photos/300/300";
  if (img.length > 0) {
    path = img[0];
  }
  return path;
}
export function LazyPages({ ...rest }) {
  return (
    <InfiniteList
      {...rest}
      defaultList={PostsList}
      loadBy={() => {
        const fetch = new PostsController();
        return fetch.get();
      }}
    />
  );
}
export function MyPosts() {
  let user = new UsersController().me();
  return (
    <GridContainer className="myPosts">
      <GridItem xs={12}>
        <h2>Mis publicaciones</h2>
        <div style={{ height: "300px", margin: "10px" }}>
          <UserChart userID={user.id} />
        </div>
      </GridItem>
      <GridItem xs={8}>
        <InfiniteList
          defaultList={PostsList}
          loadBy={() => {
            const fetch = new PostsController();
            return fetch.getByUserID({ id: user.id });
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
export function PostsByProfile({ title, profileID }) {
  const [cat, setCat] = useState({ label: "todos", value: 0 });
  const [empty, setEmpty] = useState(0);
  if (empty)
    return (
      <GridContainer>
        <GridItem xs={12}>
          <CategoriesSelector
            profileID={profileID}
            onChange={(ev) => {
              setEmpty(0);
              setCat(ev);
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <h2>{title}</h2>
          <EmptyPost />
        </GridItem>
      </GridContainer>
    );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <h2>{title}</h2>
      </GridItem>
      <GridItem xs={12}>
        <CategoriesSelector
          profileID={profileID}
          onChange={(ev) => {
            setCat(ev);
          }}
        />
      </GridItem>

      <GridItem xs={12}>
        <h5 className={"catTitle"}>{cat.label.toUpperCase()}</h5>
      </GridItem>
      <GridItem xs={12}>
        <InfiniteList
          key={cat.value}
          defaultList={PostsList}
          onFail={(content) => {
            setEmpty(1);
          }}
          loadBy={() => {
            const fetch = new PostsController();
            if (cat.value) {
              return fetch.getByCategory({ id: cat.value });
            }
            return fetch.getByProfileID({ id: profileID });
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
export function PostsByCategory({ categoryID }) {
  return (
    <InfiniteList
      defaultList={PostsList}
      key={categoryID}
      loadBy={() => {
        const fetch = new PostsController();
        if (categoryID === 0) return fetch.get();
        return fetch.getByCategory({ id: categoryID });
      }}
    />
  );
}
export function PostsByRecommendation({ id, perPage }) {
  return (
    <PostsList
      load
      loadBy={() => {
        const fetch = new PostsController();
        return fetch.getRecommended(id, perPage);
      }}
    />
  );
}
export function MedicalPosts() {
  return (
    <InfiniteList
      defaultList={SidebarPostList}
      loadBy={() => {
        const fetch = new PostsController();
        return fetch.getByProfileID({ id: 4 });
      }}
    />
  );
}
export function EmptyPost() {
  return (
    <div className="emptyPost">
      <h3> Vaya! parece que nadie ha publicado aun....</h3>
      <p>Vamos a cambiar eso!</p>
      <NavLink to="/register/post">
        <Button>Anunciate</Button>
      </NavLink>
    </div>
  );
}
export function NoveltyPosts() {
  return (
    <SecundaryHero title={"Novedades"} image={news}>
      <InfiniteList
        defaultList={PostsList}
        loadBy={() => {
          const fetch = new PostsController();
          return fetch.getNovelty();
        }}
      />
    </SecundaryHero>
  );
}
export function PopPosts() {
  return (
    <SecundaryHero title={"Populares"} image={girl}>
      <InfiniteList
        defaultList={PostsList}
        loadBy={() => {
          const fetch = new PostsController();
          return fetch.getPop();
        }}
      />
    </SecundaryHero>
  );
}
