import React from "react";
import { CSSTransition } from "react-transition-group";
import { ReactSVG } from "react-svg";
import "./scss/blocks.scss";
/* eslint eqeqeq: 0*/
export function SplashScreen({ open, icon, msg, children, background }) {
  return (
    <CSSTransition
      timeout={300}
      classNames="splashScreen"
      unmountOnExit
      in={open == true}
    >
      <div
        className="splashScreen"
        style={{
          background: `url(${background}) darkblue`,
          backgroundSize: "auto 100%",
          backgroundPosition: "center",

          backgroundAttachment: "fixed",
          backgroundBlendMode: "lighten",
        }}
      >
        <div className="content">
          <ReactSVG src={icon} />
          <h2>{msg}</h2>
        </div>
      </div>
    </CSSTransition>
  );
}
